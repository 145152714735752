<template>
    <v-container>
        <v-dialog
            v-if="overlay"
            max-width="1000"
            v-model="Object.keys(occupancy).length"
            transition="dialog-bottom-transition"
            @click:outside="$emit('update:overlay', false)"
        >
            <v-card tile elevation="10">
                <v-toolbar
                    tile
                    class="mb-4 px-4"
                    max-height="200"
                    flat
                    dark
                    :color="getColors.primary"
                >
                    <v-toolbar-title>
                        {{ getToolbarTitle() }}
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn
                        icon
                        dark
                        @click="$emit('update:overlay', false)"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-toolbar>
                <div
                    class="px-4"
                    v-for="(occupancyItems, occupancyItemsIndex) in occupancyData"
                    :key="`occupancyItemIndex-${occupancyItemsIndex}`"
                >
                    <v-row class="pl-0">
                        <v-col cols="auto">
                            <span class="title">
                                {{ occupancyItems.title }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row
                        class="pl-1 pb-1"
                        v-for="(itemDetail, itemDetailItemIndex) in occupancyItems.details"
                        :key="`itemDetailItemIndex-${itemDetailItemIndex}`"
                    >
                        <v-col class="py-0 col-4">
                            <span class="heading text--accent-1 font-weight-medium" v-html="`${itemDetail.key}:`"/>
                        </v-col>
                        <v-col class="py-0" cols="auto">
                            <span>{{ itemDetail.value.join(', ') || '-' }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pb-2">
                        <v-col cols="12">
                            <v-divider/>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'OccupancyOverlay',
    metaInfo: {
        title: 'OccupancyOverlay',
    },
    props: {
        overlay: Boolean,
        occupancy: Object,
    },
    computed: {
        ...mapGetters([
            'getColors',
        ]),
        occupancyData: function () {
            return [
                {
                    title: this.$i18n.t('common.dialogs.occupancyOverlay.heading1'),
                    details: [
                        {
                            key: this.$i18n.t('common.words.arrival'),
                            value: [this.occupancy.$_anreise],
                        },
                        {
                            key: this.$i18n.t('common.words.departure'),
                            value: [this.occupancy.$_abreise],
                        },
                        {
                            key: this.$i18n.t('common.labels.adults'),
                            value: [this.occupancy.$_erw],
                        },
                        {
                            key: this.$i18n.t('common.labels.children'),
                            value: [this.occupancy.$_kinder],
                        },
                        {
                            key: this.$i18n.t('common.labels.smallChildren'),
                            value: [this.occupancy.$_kleinkinder],
                        },
                    ],
                },
                {
                    title: this.$i18n.t('common.dialogs.occupancyOverlay.heading2'),
                    details: [
                        {
                            key: this.$i18n.t('common.labels.name'),
                            value: [this.occupancy.$_anrede + ' ' + this.occupancy.$_vorname + ' ' + this.occupancy.$_name],
                        },
                        {
                            key: this.$i18n.t('common.labels.phone'),
                            value: [this.occupancy.$_tel],
                        },
                        {
                            key: this.$i18n.t('common.labels.mail'),
                            value: [this.occupancy.$_mail],
                        },
                        {
                            key: this.$i18n.t('common.labels.mobile'),
                            value: [this.occupancy.$_handy],
                        },
                        {
                            key: this.$i18n.t('common.labels.address'),
                            value: [
                                this.occupancy.$_strasse,
                                this.occupancy.$_plz,
                                this.occupancy.$_ort,
                            ],
                        },
                        {
                            key: this.$i18n.t('common.labels.message'),
                            value: [this.occupancy.$_memo.replace(/<[^>]*>?/gm, '')],
                        },
                    ],
                },
                {
                    title: this.$i18n.t('common.dialogs.occupancyOverlay.heading3'),
                    details: [
                        {
                            key: this.$i18n.t('common.labels.propertyPrice'),
                            value: [parseFloat(this.occupancy.$_objpreis || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.servicePrice'),
                            value: [parseFloat(this.occupancy.$_leistpreis || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.spaTax'),
                            value: [parseFloat(this.occupancy.$_kurtaxe || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.deposit'),
                            value: [parseFloat(this.occupancy.$_kaution || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.prePayment'),
                            value: [parseFloat(this.occupancy.$_anzahlung || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.paid'),
                            value: [parseFloat(this.occupancy.$_gezahlt || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.finalPayment'),
                            value: [parseFloat(this.occupancy.$_rest || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.fees'),
                            value: [parseFloat(this.occupancy.$_gebuehr || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.externalCommission'),
                            value: [parseFloat(this.occupancy.$_externekommission || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                        {
                            key: this.$i18n.t('common.labels.externalCommissionMessage'),
                            value: [this.occupancy.$_externekommissiontext || ''],
                        },
                        {
                            key: this.$i18n.t('common.labels.totalPrice'),
                            value: [parseFloat(this.occupancy.$_summe || 0).toFixed(2).replace('.', ',') + ' €'],
                        },
                    ],
                },
                {
                    title: this.$i18n.t('common.dialogs.occupancyOverlay.heading4'),
                    details: [
                        {
                            key: this.$i18n.t('common.labels.ids.channelId'),
                            value: [this.occupancy.$_ssid > 0 ? this.occupancy.$_ssid : 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.restApiInterfaceId'),
                            value: [this.occupancy.$_rapiifid > 0 ? this.occupancy.$_rapiifid : 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.occupancyId'),
                            value: [this.occupancy.$_fewovorgid || 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.bookingId'),
                            value: [this.occupancy.$_feondibuchid || 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.instantBookingId'),
                            value: [this.occupancy.$_feondisbuchid || 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.instantBookingProtocolId'),
                            value: [this.occupancy.$_feondisbuchprotid || 0],
                        },
                        {
                            key: this.$i18n.t('common.labels.ids.foreignId'),
                            value: [this.occupancy.$_fremdid || 0],
                        },
                    ],
                },
            ]
        },
    },
    methods: {
        getToolbarTitle() {

            let title = this.occupancy.id || this.$i18n.tc('common.words.notAvailableShorthand')

            if (this.occupancy.$_anrede)
                title += ', ' + this.occupancy.$_anrede

            if (this.occupancy.$_vorname)
                title += ' ' + this.occupancy.$_vorname

            if (this.occupancy.$_name)
                title += ' ' + this.occupancy.$_name

            if (this.occupancy.$_erfasst)
                title += `' ${ this.$i18n.tc('common.words.from', 2).toLowerCase() } ${ moment(this.occupancy.$_erfasst).format(`${ this.$i18n.t('common.date.format') } | kk:mm:ss`) }`

            return title
        },
    },
}
</script>
