<template>
    <v-card flat class="pr-4 pb-4">
        <tab-title :title="$t('property.heading', {name: entityTitle, memberid: getActingMemberid}).toString()"/>
        <v-row v-for="(row, rowIndex) in rows" :key="`row-${rowIndex}`">
            <v-col
                class="py-0 pl-4 mt-1"
                v-for="(item, itemIndex) in row.row"
                :key="`model-${itemIndex}`"
                :cols="item.cols"
            >
                <component
                    :is="`v-${item.element}`"
                    :value="items[item.source]"
                    :v-model="items[item.source]"
                    :readonly="item.readonly"
                    :label="item.label"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import Form from '@/components/Formulare/Formular.vue'
import { mapGetters } from 'vuex'

import { VTextField, VTextarea } from 'vuetify/lib'

export default {
    name: 'BasicDataTab',
    components: {
        TabTitle,
        Form,
        VTextField,
        VTextarea,
    },
    props: {
        items: Object,
        entityTitle: String,
    },
    computed: {
        ...mapGetters([
            'getForm',
            'getActingMemberid',
            'getTab',
        ]),
        rows: function () {
            return [
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.street'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_strasse',
                            cols: 10,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.country'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_land',
                            cols: 1,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.zip'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_plz',
                            cols: 1,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.city'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_ort',
                            cols: 8,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.lat'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_lat',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.long'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_lng',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.shortDescription'),
                            element: 'textarea',
                            type: 'text',
                            source: 'objlng_kurztext',
                            cols: 10,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.titlePlatforms'),
                            element: 'textarea',
                            type: 'text',
                            source: 'objlng_titelportale',
                            cols: 10,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.number'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_nummer',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.internalNumber'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_intnr',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.registerNumber'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_registriernr',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.houseId'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_hausid',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },

                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.persons'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_personen',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.adults'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_erwachsene',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },

                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.children'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_kinder',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.rooms'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_zimmer',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },

                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.beds'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_betten',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.sizeOfUnit'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_groesse',
                            suffix: 'm²',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },

                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.sizeOfPlot'),
                            element: 'text-field',
                            type: 'number',
                            source: 'obj_grundstuecksflaeche',
                            suffix: 'm²',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.wlanName'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_wlanname',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.wlanPassword'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_wlanpassword',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.arrivalFrom'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_anreisevon',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.arrivalTo'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_anreisebis',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.departureFrom'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_abreisevon',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.departureFrom'),
                            element: 'text-field',
                            type: 'text',
                            source: 'obj_abreisebis',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.propertyTypeId'),
                            element: 'text-field',
                            type: 'text',
                            source: 'art_id',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.propertyTypeFeondiTitle'),
                            element: 'text-field',
                            type: 'text',
                            source: 'artstds_beschreibung',
                            cols: 5,
                            readonly: true,
                        },
                        {
                            label: this.$i18n.t('common.labels.propertyTypeTitle'),
                            element: 'text-field',
                            type: 'text',
                            source: 'artlng_titel',
                            cols: 5,
                            readonly: true,
                        },
                    ],
                },
            ]
        },
    },
}
</script>
