<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('distances.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    >
                    </search>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-4 pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        show-expand
                        :single-expand="true"
                        :expanded.sync="expanded"
                        :item-key="randomKeyToAvoidDuplicateKeyErrors('row', items.length, items.length)"
                        :search="search"
                        :loading="$store.getters.getLoaders.distances"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        @click:row="rowClick"
                    >
                        <template v-slot:item.objentf_km="{ item }">
                            <span v-if="item.objentf_km">
                                {{ item.objentf_km.toFixed(2).replace('.', ',') }}
                            </span>
                        </template>
                        <template v-slot:item.entf_strasse="{ item }">
                            <span v-if="item.entf_strasse">
                                {{ item.entf_strasse }}
                            </span>
                        </template>
                        <template v-slot:item.entf_plz="{ item }">
                            <span v-if="item.entf_plz">
                                {{ item.entf_plz }}
                            </span>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th
                                                v-for="(headColumns, headColumnsIndex) in [$tc('common.words.channels', 2), $t('common.labels.mappingCode')]"
                                                :key="randomKeyToAvoidDuplicateKeyErrors('mappingCodeColumnIndexHead', headColumnsIndex, item.id)"
                                                class="text-left text-uppercase font-weight-black"
                                            >
                                                {{ headColumns }}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            style=";background-color:rgba(0,0,0,0.03)"
                                            v-for="(mappingCodeColumn, mappingCodeColumnIndex) in mappingCodeColumns"
                                            :key="randomKeyToAvoidDuplicateKeyErrors('mappingCodeColumnIndexTableRow', mappingCodeColumnIndex, item.id)"
                                        >
                                            <td
                                                style="border-radius:0"
                                                class="v-data-table__divider"
                                                :style="`width:${mappingCodeColumn.width}`"
                                            >
                                                {{ mappingCodeColumn.channel }}
                                            </td>
                                            <td
                                                style="border-radius:0"
                                                class="v-data-table__divider"
                                                :style="`width:${mappingCodeColumn.width}`"
                                            >
                                                  <span v-if="item[mappingCodeColumn.column]">
                                                    {{ item[mappingCodeColumn.column] }}
                                                  </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'

const name = 'Distances'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String,
    },
    data() {
        return {
            name: name,
            expanded: [],
            search: '',
            mappingCodeColumns: [
                {
                    channel: 'Booking.com (attraction)',
                    width: '',
                    column: 'entfstds_bookingcom_attractions',
                },
                {
                    channel: 'Booking.com (transportation)',
                    width: '',
                    column: 'entfstds_bookingcom_transportations',
                },
                {
                    channel: 'HomeAway',
                    width: '',
                    column: 'entfstds_homeaway',
                },
                {
                    channel: 'Yoholida',
                    width: '',
                    column: 'entfstds_yoholida',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'entflng_titel',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.feondiTitle'),
                    value: 'entfstds_beschreibung',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.distance'),
                    value: 'objentf_km',
                    align: 'end',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.street'),
                    value: 'entf_strasse',
                    divider: true,
                    width: '180px',
                },
                {
                    text: this.$i18n.t('common.labels.zip'),
                    value: 'entf_plz',
                    align: 'end',
                    divider: true,
                    width: '130px',
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: '50px',
                },
            ]
        },
    },
    methods: {
        randomKeyToAvoidDuplicateKeyErrors(indexDescription, key1, key2) {
            return indexDescription + '-' + Math.floor(Math.random() * this.items.length) + '-' + key1 + '-' + key2
        },
        rowClick(item, slot) {
            slot.expand(!slot.isExpanded)
        },
    },
}
</script>
