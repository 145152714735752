<template>
    <div>
        <occupancy-overlay
            :occupancy="occupancy"
            v-if="overlay"
            :overlay.sync="overlay"
        />
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('occupancies.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pa-6">
                    <v-row class="fill-height">
                        <v-col class="pl-0">
                            <v-sheet height="64">
                                <v-toolbar flat dense class="pl-0">
                                    <v-btn
                                        text
                                        ripple
                                        color="darken-2"
                                        @click="setToday"
                                    >
                                        {{ $t('common.date.today') }}
                                        <v-icon right>
                                            mdi-calendar-today
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-for="(prevNetxtButton, index) in prevNextButtons"
                                        :kex="`prevNextButton-${index}`"
                                        fab
                                        text
                                        small
                                        color="grey darken-2"
                                        @click="prevNetxtButton.action"
                                    >
                                        <v-icon small>
                                            {{ prevNetxtButton.icon }}
                                        </v-icon>
                                    </v-btn>
                                    <v-toolbar-title class="pl-3 v-btn text-button">
                                        {{ $refs.calendar ? $refs.calendar.title : getCurrentMonth() }}
                                    </v-toolbar-title>
                                    <v-spacer/>
                                    <v-menu bottom right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text ripple color="darken-2" v-bind="attrs" v-on="on">
                                                <span>
                                                    {{ typeToLabel[type] }}
                                                </span>
                                                <v-icon right>
                                                    mdi-menu-down
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(menuItem, menuItemsIndex) in menuItems"
                                                :key="`menu-item-key${menuItemsIndex}`"
                                                @click="type = menuItem.type"
                                            >
                                                <v-list-item-title>
                                                    <span :style="`color: ${(type === menuItem.type ? getColors.secondary1 : getColors.primary)}`">
                                                        {{ menuItem.title }}
                                                    </span>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-toolbar>
                            </v-sheet>
                            <v-sheet height="600">
                                <v-calendar
                                    :locale="getLocale"
                                    :weekdays="weekdays"
                                    event-ripple
                                    ref="calendar"
                                    v-model="focus"
                                    :color="getColors.error"
                                    show-week
                                    :events="occupancies"
                                    :event-color="items.color"
                                    :type="type"
                                    @click:event="openOccupancyMenu"
                                    @click:date="viewDay"
                                    @click:more="viewWeek"
                                >
                                    <template v-slot:day-label="day">
                                        <v-btn
                                            v-if="day.present"
                                            class="elevation-0 white--text"
                                            fab
                                            small
                                            :color="getColors.error"
                                            @click="viewDay(day)"
                                        >
                                            {{ day.day }}
                                        </v-btn>
                                    </template>
                                </v-calendar>
                                <v-menu
                                    max-width="450px"
                                    v-model="selectedOpen"
                                    :close-on-content-click="false"
                                    :activator="selectedElement"
                                    offset-x
                                >
                                    <v-card
                                        color="grey lighten-4"
                                        min-width="350px"
                                        flat
                                        tile
                                    >
                                        <v-toolbar
                                            class="px-4"
                                            dark
                                            :color="getColors.primary"
                                        >
                                            <v-toolbar-title v-html="selectedEvent.name"/>
                                            <v-spacer/>
                                            <v-progress-circular v-if="$store.getters.getLoaders.occupancyMenu" indeterminate/>
                                            <v-btn
                                                v-else
                                                icon
                                                @click="showOverlay"
                                            >
                                                <v-icon>
                                                    mdi-eye
                                                </v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text v-if="selectedOpen">
                                            <v-card-title class="pl-0" v-html="`${this.$i18n.t('common.labels.createdAt')}: ${eventDetails(selectedEvent.details).createdAt}`"/>
                                            <v-divider/>
                                            <div class="d-flex flex-column mt-4">
                                                <div
                                                    class="py-0"
                                                    v-for="(eventDetailsType, index) in eventDetailsTypes"
                                                    :key="`listIndex-${index}`"
                                                    v-html="eventDetailsType.detail(selectedEvent)"
                                                >
                                                </div>
                                            </div>
                                        </v-card-text>
                                        <v-divider/>
                                        <v-card-actions>
                                            <v-btn text :color="getColors.primary" @click="closeOccupancyMenu">
                                                {{ $t('common.words.close') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import OccupancyOverlay from '@/components/Objekt/Vorgangsdetails.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PropertiesService from '@/services/properties.service'

const name = 'Occupancies'

export default {
    name: name,
    components: { OccupancyOverlay },
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String,
        entityId: Number
    },
    data() {
        return {
            name: name,
            occupancies: [],
            occupancy: [],
            overlay: false,
            prevNextButtons: [
                {
                    icon: 'mdi-chevron-left',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    icon: 'mdi-chevron-right',
                    action: () => {
                        this.next()
                    },
                },
            ],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            currentMonth: '',
            focus: '',
            type: 'month',
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLocale',
            'getActingMemberid',
        ]),
        typeToLabel: function () {
            return {
                month: this.$i18n.t('common.date.month'),
                week: this.$i18n.t('common.date.week'),
                day: this.$i18n.t('common.date.day'),
            }
        },
        menuItems: function () {
            return [
                {
                    title: this.$i18n.t('common.date.month'),
                    type: 'month',
                },
                {
                    title: this.$i18n.t('common.date.week'),
                    type: 'week',
                },
                {
                    title: this.$i18n.t('common.date.day'),
                    type: 'day',
                },
            ]
        },
        eventDetailsTypes: function () {
            return [
                {
                    detail: (selectedEvent) => {
                        return `${ this.$i18n.t('common.labels.currentStatus') }: <b>${ this.eventDetails(selectedEvent.details).status }</b>`
                    },
                },
                {
                    detail: (selectedEvent) => {
                        return `${ this.$i18n.t('common.labels.sourceType') }: <b>${ this.eventDetails(selectedEvent.details).type }</b>`
                    },
                },
                {
                    detail: (selectedEvent) => {
                        return `${ this.$i18n.t('common.words.arrival') }: <b>${ this.getFormattedDate(selectedEvent.start) }</b> | ${ this.$i18n.t('common.words.departure') }: <b>${ this.getFormattedDate(selectedEvent.end) }</b>`
                    },
                },
            ]
        },
    },
    watch: {
        '$store.state.propertyOccupancies': function () {
            this.$store.dispatch('setLoaders', { occupancies: true })
            this.createClenderEvents()
            this.$store.dispatch('setLoaders', { occupancies: false })
        },
    },
    methods: {
        getFormattedDate(dateAsString) {
            if (dateAsString)
                return moment(dateAsString).format(this.$i18n.t('common.date.format'))

            return ''
        },
        getCurrentMonth() {
            moment.locale(this.getLocale)
            return moment().format('MMMM YYYY')
        },
        viewDay({ date }) {

            logger.log(`this is date object in view day: `, date)

            this.focus = date
            this.type = 'day'
        },
        viewWeek({ date }) {
            this.focus = date
            this.type = 'week'
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showOverlay() {
            this.selectedOpen = false
            this.overlay = true
        },
        closeOccupancyMenu() {
            this.selectedOpen = false
        },
        async openOccupancyMenu({ nativeEvent, event }) {
            await this.$store.dispatch('setLoaders', { occupancyMenu: true })
            this.occupancy = await PropertiesService.getPropertyOccupancy({
                memberid: this.getActingMemberid,
                objid: this.entityId,
                occupancyid: event.details.id,
                occupancytype: event.details.art,
            })
            await this.$store.dispatch('setLoaders', { occupancyMenu: false })

            const open = async () => {
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
            }

            if (this.selectedOpen) {
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
                this.selectedOpen = false
            } else
                await open()

            nativeEvent.stopPropagation()
        },
        eventDetails(occupancy) {
            const type = occupancy.vorg_art.toUpperCase()
            let color = '#8e44ad'

            switch (type) {
                case 'A':
                    color = '#1abc9c'
                    break

                case 'AA':
                    color = '#16a085'
                    break

                case 'AL':
                    color = '#2ecc71'
                    break

                case 'B':
                    color = '#3498db'
                    break

                case 'BL':
                    color = '#34495e'
                    break

                case 'E':
                    color = '#2c3e50'
                    break

                case 'L':
                    color = '#c0392b'
                    break

                case 'O':
                    color = '#198e86'
                    break

                case 'RE':
                    color = '#e67e22'
                    break

                case 'S':
                    color = '#f39c12'
                    break

                case 'SB':
                    color = '#bdc3c7'
                    break

                case 'SL':
                    color = '#7f8c8d'
                    break

                case 'SMB':
                    color = '#95a5a6'
                    break
            }

            return {
                type: this.$i18n.t(`common.labels.${ occupancy.art.toLowerCase() }`),
                color: color,
                status: `${ this.$i18n.t(`common.labels.bookingTypes.${ type.toLowerCase() }`) } (${ type })`,
                createdAt: moment(new Date(occupancy.erfasst)).format(`${ this.$i18n.t('common.date.format') } | kk:mm:ss`),
            }
        },
        createClenderEvents() {
            this.items.forEach(occupancy => {
                this.occupancies.push({
                    name: occupancy.id + ` | ${ occupancy.vorg_art } | ` + occupancy.vorg_name + ' ' + occupancy.vorg_vorname,
                    start: occupancy.vorg_anreise,
                    end: occupancy.vorg_abreise,
                    color: this.eventDetails(occupancy).color,
                    timed: false,
                    details: occupancy,
                })
            })
        },
    },
    mounted() {
        this.$refs.calendar.checkChange()
    },
    created() {
        this.createClenderEvents()
    },
}
</script>
