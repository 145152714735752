<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('services.heading1', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <div
                class="mt-4"
                v-for="(elem, elemIndex) in items"
                :key="`elementsIndex-${elemIndex}`"
            >
                <v-row>
                    <v-card-title class="pb-0" v-html="$t(`services.${elemIndex}`)"/>
                </v-row>
                <v-row>
                    <v-col class="pl-0">
                        <v-data-table
                            :headers="headers"
                            :items="elem"
                            show-expand
                            :single-expand="true"
                            :expanded.sync="expanded[elemIndex]"
                            :search="search"
                            :loading="$store.getters.getLoaders.services"
                            :loading-text="$t('common.datatable.loading')"
                            :no-data-text="$t('common.datatable.noData')"
                            :no-results-text="$t('common.datatable.noResults')"
                            :items-per-page="25"
                            :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                          }"
                            class="elevation-2 ma-3 cursor-pointer"
                            @click:row="rowClick"
                        >
                            <template v-slot:item.leiststds_beschreibung="{ item }">
                                <span v-if="item.leiststds_beschreibung">
                                    {{ item.leiststds_beschreibung }}
                                </span>
                                <span
                                    v-else
                                    :style="`color: ${getColors.error}`"
                                    v-html="$t('common.words.notAssigned')"
                                />
                            </template>
                            <template v-slot:item.leist_mwst="{ item }">
                                <span>
                                    {{ parseFloat(item.leist_mwst).toFixed(2).replace('.', ',') + ' %' }}
                                </span>
                            </template>
                            <template v-slot:item.leist_person="{ item }">
                                <activated-deactivated-icon :is-set="item.leist_person"/>
                            </template>
                            <template v-slot:item.leist_tag="{ item }">
                                <activated-deactivated-icon :is-set="item.leist_tag"/>
                            </template>
                            <template v-slot:item.leist_nobookingcom="{ item }">
                                <activated-deactivated-icon :is-set="item.leist_nobookingcom"/>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td class="px-0" :colspan="headers.length">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th
                                                    v-for="(headColumns, headColumnsIndex) in [$tc('common.words.channels', 2), $t('common.labels.mappingCode')]"
                                                    :key="`mappingCodeColumnIndex-${elemIndex}-${headColumnsIndex}`"
                                                    class="text-left text-uppercase font-weight-black"
                                                >
                                                    {{ headColumns }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                style=";background-color:rgba(0,0,0,0.03)"
                                                v-for="(mappingCodeColumn, mappingCodeColumnIndex) in mappingCodeColumns"
                                                :key="`mappingCodeColumnIndex-${elemIndex}-${mappingCodeColumnIndex}`"
                                            >
                                                <td
                                                    style="border-radius:0"
                                                    class="v-data-table__divider"
                                                    :style="`width:${mappingCodeColumn.width}`"
                                                >
                                                    {{ mappingCodeColumn.channel }}
                                                </td>
                                                <td
                                                    style="border-radius:0"
                                                    class="v-data-table__divider"
                                                    :style="`width:${mappingCodeColumn.width}`"
                                                >
                                                    <span v-if="item[mappingCodeColumn.column]">
                                                        {{ item[mappingCodeColumn.column] }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        :style="`color: ${getColors.error}`"
                                                        v-html="$t('common.words.notAssigned')"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'

const name = 'Services'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Object,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            expanded: {
                bookable: [],
                additional: [],
            },
            search: '',
            mappingCodeColumns: [
                {
                    channel: 'Booking.com',
                    column: 'leiststds_bookingcom',
                    width: '',
                },
                {
                    channel: 'Airbnb',
                    column: 'leiststds_airbnb_rest',
                    width: '',
                },
                {
                    channel: 'Feratel',
                    column: 'leiststds_feratel',
                    width: '',
                },
                {
                    channel: 'Holidu',
                    column: 'leiststds_holidu',
                    width: '',
                },
                {
                    channel: 'HomeAway',
                    column: 'leiststds_homeaway',
                    width: '',
                },
                {
                    channel: 'Yoholida',
                    column: 'leiststds_yoholida_listing',
                    width: '',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'leistlng_titel',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.feondiTitle'),
                    value: 'leiststds_beschreibung',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.price'),
                    value: 'leist_preis',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.tax'),
                    value: 'leist_mwst',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.perPerson'),
                    value: 'leist_person',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.perDay'),
                    value: 'leist_tag',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.words.notTo') + ' Booking.com',
                    value: 'leist_nobookingcom',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '100px',
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: '50px',
                },
            ]
        },
    },
    methods: {
        rowClick(item, slot) {
            slot.expand(!slot.isExpanded)
        },
    },
}
</script>
