<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('offers.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :single-expand="true"
                        :expanded.sync="expanded"
                        :search="search"
                        :loading="$store.getters.getLoaders.distances"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                    >
                        <template v-slot:item.ang_von="{ item }">
                            <span>
                                {{ getFormattedDate(item.ang_von) }}
                            </span>
                        </template>
                        <template v-slot:item.ang_bis="{ item }">
                            <span>
                                {{ getFormattedDate(item.ang_bis) }}
                            </span>
                        </template>
                      <template v-slot:item.ang_buchungszeitraumvon="{ item }">
                            <span>
                                {{ getFormattedDate(item.ang_buchungszeitraumvon) }}
                            </span>
                        </template>
                        <template v-slot:item.ang_buchungszeitraumbis="{ item }">
                            <span>
                                {{ getFormattedDate(item.ang_buchungszeitraumbis) }}
                            </span>
                        </template>
                        <template v-slot:item.ang_typ="{ item }">
                            <span>
                                {{ getLocaleTextValue('types', item.ang_typ) }}
                            </span>
                        </template>
                        <template v-slot:item.ang_art="{ item }">
                            <span>
                                {{ getLocaleTextValue('kinds', item.ang_art) }}
                            </span>
                        </template>
                        <template v-slot:item.ang_prozent="{ item }">
                            <span>
                                {{ parseFloat(item.ang_prozent).toFixed(2).replace('.', ',') + ' %' }}
                            </span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'

const name = 'Offers'

export default {
    name: name,
    components: { Search },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            expanded: [],
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'anglng_titel',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('offers.valid.from'),
                    value: 'ang_von',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('offers.valid.until'),
                    value: 'ang_bis',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('offers.bookable.from'),
                    value: 'ang_buchungszeitraumvon',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('offers.bookable.until'),
                    value: 'ang_buchungszeitraumbis',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.words.type'),
                    value: 'ang_typ',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.words.art'),
                    value: 'ang_art',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.bookDays'),
                    value: 'ang_tagebuchen',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.payDays'),
                    value: 'ang_tagezahlen',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.fixPrice'),
                    value: 'ang_festpreis',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.daysBefore'),
                    value: 'ang_tagevor',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.discount'),
                    value: 'ang_prozent',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
            ]
        },
    },
    methods: {
        getLocaleTextValue(typeOrKind, value) {
            return this.$i18n.t(`offers.${ typeOrKind }.${ value }`)
        },
        getFormattedDate(dateAsString) {
            if (dateAsString)
                return moment(dateAsString).format(this.$i18n.t('common.date.format').toString())

            return ''
        },
    },
}
</script>
