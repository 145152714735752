<template>
    <div>
        <v-overlay
            v-if="overlay.visible"
            :opacity="0.99"
            :color="getColors.primary"
        >
            <v-container>
                <v-row dense justify="end">
                    <v-icon
                        right
                        style="cursor: pointer"
                        @click="closeOverlay()"
                    >
                        mdi-close
                    </v-icon>
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-carousel hide-delimiters>
                            <v-carousel-item
                                contain
                                ripple
                                style="width: 100%; background-color: rgb(40,40,40);"
                                v-for="(overlayPhoto, overlayPhotoIndex) in overlay.photos"
                                :key="`item-${overlayPhotoIndex}`"
                                :lazy-src="getImage(overlayPhoto)"
                                :src="getImage(overlayPhoto)"
                            />
                        </v-carousel>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>

        <v-card flat class="pr-4 pb-4">
            <tab-title
                :title="$tc('photos.heading', items.length, {type: entityTypeTranslated, name: entityTitle, memberid: getActingMemberid})"
                :tooltip="true"
            />
            <div v-if="items.length" class="d-flex flex-row flex-wrap justify-start">
                <div
                    v-for="(photo, photoIndex) in items"
                    :key="`photo-${photoIndex}`"
                    style="max-width: 450px"
                    class="mb-6 mr-6"
                >
                    <v-card>
                        <v-img
                            :src="getImage(photo)"
                            :lazy-src="getImage(photo)"
                            style="cursor: pointer"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,.8)"
                            height="300px"
                            position="center center"
                            @click="sortArrayOpenOverlay(photoIndex)"
                        >
                            <v-card-title>
                                <div class="d-flex flex-column w-100">
                                    <div class="text--accent-1">
                                        {{ photo.imglng_titel && photo.imglng_titel.slice(0, 35 - 3) + (photo.imglng_titel.length >= 35 ? '...' : '') }}
                                    </div>
                                    <div class="text-caption">
                                        {{ getImageOrientation(photo) }}
                                    </div>
                                </div>
                            </v-card-title>
                        </v-img>
                        <v-card-actions class="pl-0">
                            <v-card-subtitle>
                                {{ getSortOrMainPhoto(photo) }}
                            </v-card-subtitle>
                            <v-spacer/>
                            <v-card-subtitle>
                                {{ photo.img_sizeof.type.toUpperCase() }}, {{ photo.img_sizeof.width }}x{{ photo.img_sizeof.height }} px
                                <v-tooltip bottom v-if="photo.img_sizeof.width < 1280 || photo.img_sizeof.height < 900">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="mr-0"
                                            :color="getColors.error"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-alert-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        {{ $t('common.tooltips.recommendedImageSize', {
                                      w: photo.img_sizeof.width,
                                      h: photo.img_sizeof.height,
                                    }) }}
                                    </span>
                                </v-tooltip>
                                <v-icon v-else :color="getColors.success">
                                    mdi-check
                                </v-icon>
                            </v-card-subtitle>
                        </v-card-actions>
                    </v-card>
                </div>
            </div>
            <no-data v-else :text="$t('photos.noPhotos', { type: entityTypeTranslated, name: entityTitle })"/>
        </v-card>
    </div>
</template>

<script>
import NoData from '@/components/Allgemein/KeineDaten.vue'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import TTCustom from '@/components/Tooltips/TTFreierText.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'PhotosTab',
    components: {
        NoData,
        TabTitle,
        TTCustom,
    },
    props: {
        entityType: String,
        entityId: Number,
        entityTitle: String,
        items: Array,
        sortImages: String,
    },
    data() {
        return {
            overlay: {
                visible: false,
                photos: [],
            },
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        entityTypeTranslated: function () {
            return this.$i18n.tc(`common.words.${ this.entityType }`, 1)
        },
    },
    methods: {
        getImageOrientation(photo) {
            const width = photo.img_sizeof.width
            const height = photo.img_sizeof.height

            switch (true) {
                case width > height:
                    return this.$i18n.tc('photos.landscape')
                case width < height:
                    return this.$i18n.tc('photos.portrait')
                default:
                    return this.$i18n.tc('photos.square')
            }
        },
        sortArrayOpenOverlay(index) {
            const firstSlice = this.items.slice(index)
            const secondSlice = this.items.slice(0, index)

            this.overlay.photos = [...firstSlice, ...secondSlice]
            this.overlay.visible = true
        },
        closeOverlay() {
            this.overlay.visible = false
            this.overlay.photos.length = 0
        },
        showOverlay(card) {
            this.overlay.img = card.src
            this.overlay.visible = true
        },
        getSortOrMainPhoto(photo) {
            const sort = this.getSort(photo.img_hash)
            let text = this.$i18n.tc('photos.sort', sort)

            if (photo.img_bild.includes('grundrisse'))
                text = this.$i18n.tc('photos.floorplan')
            else if (!sort)
                text = this.$i18n.tc('photos.unsorted')
            else if (sort === 1)
                text = this.$i18n.tc('photos.main')

            return text
        },
        getSort(hash) {
            if (this.sortImages)
                return this.sortImages.split(',').findIndex(element => element === hash) + 1

            return 0
        },
        getImage(photo) {

            let type = 'objekt'

            if (photo.img_bild.includes('haus'))
                type = 'haus'
            if (photo.img_bild.includes('grundrisse'))
                type = 'grundriss'

            return `https://api.feondi.de/common/image.php?u=xsigns&p=sfgashfasdfajshbfkas&art=${ type }&memberid=${ this.getActingMemberid }&entityid=${ this.entityId }&md5=${ photo.img_hash }`
        },
    },
}
</script>
