var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(_vm.$t('offers.heading', {name: _vm.entityTitle, memberid: _vm.getActingMemberid}))}}),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-0"},[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"single-expand":true,"expanded":_vm.expanded,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.distances,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ang_von",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.ang_von))+" ")])]}},{key:"item.ang_bis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.ang_bis))+" ")])]}},{key:"item.ang_buchungszeitraumvon",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.ang_buchungszeitraumvon))+" ")])]}},{key:"item.ang_buchungszeitraumbis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.ang_buchungszeitraumbis))+" ")])]}},{key:"item.ang_typ",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getLocaleTextValue('types', item.ang_typ))+" ")])]}},{key:"item.ang_art",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getLocaleTextValue('kinds', item.ang_art))+" ")])]}},{key:"item.ang_prozent",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.ang_prozent).toFixed(2).replace('.', ',') + ' %')+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }