var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(_vm.$t('amenities.heading', {name: _vm.entityTitle, memberid: _vm.getActingMemberid}))}}),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-4 pl-0"},[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"show-expand":"","single-expand":true,"expanded":_vm.expanded,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.distances,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.objausstds_beschreibung",fn:function(ref){
                  var item = ref.item;
return [(item.objausstds_beschreibung)?_c('span',[_vm._v(" "+_vm._s(item.objausstds_beschreibung)+" ")]):_c('span',{style:(("color: " + (_vm.getColors.error))),domProps:{"innerHTML":_vm._s(_vm.$t('common.words.notAssigned'))}})]}},{key:"item.objaus_wert",fn:function(ref){
                  var item = ref.item;
return [(item.objaus_wert)?_c('span',[_vm._v(" "+_vm._s(item.objaus_wert)+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l(([_vm.$tc('common.words.channels', 2), _vm.$t('common.labels.mappingCode')]),function(headColumns,headColumnsIndex){return _c('th',{key:("mappingCodeColumnIndex-" + headColumnsIndex),staticClass:"text-left text-uppercase font-weight-black"},[_vm._v(" "+_vm._s(headColumns)+" ")])}),0)]),_c('tbody',_vm._l((_vm.mappingCodeColumns),function(mappingCodeColumn,mappingCodeColumnIndex){return _c('tr',{key:("mappingCodeColumnIndex-" + mappingCodeColumnIndex),staticStyle:{"background-color":"rgba(0,0,0,0.03)"}},[_c('td',{staticClass:"v-data-table__divider",staticStyle:{"border-radius":"0"},style:(("width:" + (mappingCodeColumn.width)))},[_vm._v(" "+_vm._s(mappingCodeColumn.channel)+" ")]),_c('td',{staticClass:"v-data-table__divider",staticStyle:{"border-radius":"0"},style:(("width:" + (mappingCodeColumn.width)))},[(item[mappingCodeColumn.column])?_c('span',[_vm._v(" "+_vm._s(item[mappingCodeColumn.column])+" ")]):_vm._e()])])}),0)]},proxy:true}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }