var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.$t('prices.heading', { name: _vm.entityTitle, memberid: _vm.getActingMemberid }))+" "),_c('v-spacer'),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"plain":"","text":"","small":"","color":_vm.getColors.secondary2},on:{"click":function($event){_vm.pricesFiltered = !_vm.pricesFiltered}}},[_vm._v(" "+_vm._s(_vm.$t('prices.endsBefore', { state: _vm.showHideTranslated, date: _vm.currentDateFormatLocale }))+" ")])],1)],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-4 pl-0"},[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.priceItems,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.prices,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  },"single-select":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.preis_von",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.preis_von))+" ")])]}},{key:"item.preis_bis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.preis_bis))+" ")])]}},{key:"item.preis_preis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.preis_preis).toFixed(2).replace('.', ','))+" ")])]}},{key:"item.preis_wochenpreis",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:('color: ' + (item.preis_wochenpreis > 0 && item.preis_wochenpreis <= item.preis_preis ? _vm.$store.getters.getColors.error : ''))},[_vm._v(" "+_vm._s(parseFloat(item.preis_wochenpreis).toFixed(2).replace('.', ','))+" ")])]}},{key:"item.preis_weitere",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.preis_weitere).toFixed(2).replace('.', ','))+" ")])]}},{key:"item.preis_mwst",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.preis_mwst).toFixed(2).replace('.', ',') + ' %')+" ")])]}},{key:"item.preis_mintage",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.preis_mintage)+" ")])]}},{key:"item.preis_anreise",fn:function(ref){
                  var item = ref.item;
return [(item.preis_anreise.split(',').length === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.getArrivalAndDepartureDaysText(item.preis_anreise))+" ")]):_c('t-t-custom',{attrs:{"tt-message":_vm.getArrivalAndDepartureDaysText(item.preis_anreise),"tt-icon":"mdi-information-outline","tt-color":_vm.$store.getters.getColors.warning}})]}},{key:"item.preis_abreise",fn:function(ref){
                  var item = ref.item;
return [(item.preis_abreise.split(',').length === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.getArrivalAndDepartureDaysText(item.preis_abreise))+" ")]):_c('t-t-custom',{attrs:{"tt-message":_vm.getArrivalAndDepartureDaysText(item.preis_abreise),"tt-icon":"mdi-information-outline","tt-color":_vm.$store.getters.getColors.warning}})]}},{key:"item.preis_lueckenbuchungok",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.preis_lueckenbuchungok}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }