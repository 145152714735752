<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0">
                {{ $t('surcharges.heading', { name: entityTitle, memberid: getActingMemberid }) }}
            </v-card-title>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-4 pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :loading="$store.getters.getLoaders.surcharges"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                    >
                        <template v-slot:item.zu_von="{ item }">
                            <span>
                                {{ getFormattedDate(item.zu_von) }}
                            </span>
                        </template>
                        <template v-slot:item.zu_bis="{ item }">
                            <span>
                                {{ getFormattedDate(item.zu_bis) }}
                            </span>
                        </template>
                        <template v-slot:item.zu_prozent="{ item }">
                            <span>
                                {{ parseFloat(item.zu_prozent).toFixed(2).replace('.', ',') + ' %' }}
                            </span>
                        </template>
                        <template v-slot:item.zu_preis="{ item }">
                            <span>
                                {{ parseFloat(item.zu_preis).toFixed(2).replace('.', ',') }}
                            </span>
                        </template>
                        <template v-slot:item.zu_zeitraumaktiv="{ item }">
                            <activated-deactivated-icon :is-set="item.zu_zeitraumaktiv"/>
                        </template>
                        <template v-slot:item.zu_kurzbucher="{ item }">
                            <activated-deactivated-icon :is-set="item.zu_kurzbucher"/>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'

const name = 'Surcharges'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.title'),
                    value: 'zu_name',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.feondiPropertyType'),
                    value: 'artstds_beschreibung',
                    divider: true,
                    width: '170px',
                },
                {
                    text: this.$i18n.t('common.labels.from'),
                    value: 'zu_von',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.to'),
                    value: 'zu_bis',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.percent'),
                    value: 'zu_prozent',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.price'),
                    value: 'zu_preis',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.persons'),
                    value: 'zu_personen',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.daterange'),
                    value: 'zu_zeitraumaktiv',
                    align: 'center',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.shortBooking'),
                    value: 'zu_kurzbucher',
                    align: 'center',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.days'),
                    value: 'zu_tage',
                    align: 'end',
                    divider: true,
                    width: '80px',
                },
            ]
        },
    },
    methods: {
        getFormattedDate(dateAsString) {
            if (dateAsString)
                return moment(dateAsString).format(this.$i18n.t('common.date.format').toString())

            return ''
        },
    },
}
</script>
