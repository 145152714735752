<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('rooms.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <div v-if="items.length">
                <v-row>
                    <v-col class="pr-6">
                        <search
                            :value="search"
                            ref="searchComponent"
                            @input="search = $event"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pl-0">
                        <v-card
                            flat
                            class="mb-4"
                            v-for="(elem, elemIndex) in items"
                            :key="`elementsIndex-${elemIndex}`"
                        >
                            <v-card-title>
                                {{ getRoomName(elem).title }}
                                <v-icon
                                    class="pl-2"
                                    color="#000"
                                >
                                    {{ getRoomName(elem).icon }}
                                </v-icon>
                            </v-card-title>
                            <v-card-subtitle>
                                {{ getRoomName(elem).subtitle }}
                            </v-card-subtitle>
                            <v-data-table
                                :headers="headers"
                                :items="elem.amenities"
                                show-expand
                                :single-expand="true"
                                :expanded.sync="expanded[elemIndex]"
                                :search="search"
                                :loading="$store.getters.getLoaders.services"
                                :loading-text="$t('common.datatable.loading')"
                                :no-data-text="$t('common.datatable.noData')"
                                :no-results-text="$t('common.datatable.noResults')"
                                :items-per-page="25"
                                :footer-props="{
                              'items-per-page-options': [25, 50, 100, -1],
                              'items-per-page-text': $t('common.datatable.rowsPerPage'),
                              'items-per-page-all-text': $t('common.words.all')
                              }"
                                class="elevation-2 mx-3 cursor-pointer"
                                @click:row="rowClick"
                            >
                                <template v-slot:item.objausstds_beschreibung="{ item }">
                                    <span v-if="item.objausstds_beschreibung">
                                        {{ item.objausstds_beschreibung }}
                                    </span>
                                    <span
                                        v-else
                                        :style="`color: ${getColors.error}`"
                                        v-html="$t('common.words.notAssigned')"
                                    />
                                </template>
                                <template v-slot:item.rk2aus_wert="{ item }">
                                    <span v-if="item.rk2aus_wert">
                                        {{ item.rk2aus_wert }}
                                    </span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td class="px-0" :colspan="headers.length">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th
                                                        v-for="(headColumns, headColumnsIndex) in [$tc('common.words.channels', 2), $t('common.labels.mappingCode')]"
                                                        :key="`mappingCodeColumnIndex-${headColumnsIndex}`"
                                                        class="text-left text-uppercase font-weight-black"
                                                    >
                                                        {{ headColumns }}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr
                                                    style=";background-color:rgba(0,0,0,0.03)"
                                                    v-for="(mappingCodeColumn, mappingCodeColumnIndex) in mappingCodeColumns"
                                                    :key="`mappingCodeColumnIndex-${mappingCodeColumnIndex}`"
                                                >
                                                    <td
                                                        style="border-radius:0"
                                                        class="v-data-table__divider"
                                                        :style="`width:${mappingCodeColumn.width}`"
                                                    >
                                                        {{ mappingCodeColumn.channel }}
                                                    </td>
                                                    <td
                                                        style="border-radius:0"
                                                        class="v-data-table__divider"
                                                        :style="`width:${mappingCodeColumn.width}`"
                                                    >
                                                        <span v-if="item[mappingCodeColumn.column]">
                                                            {{ item[mappingCodeColumn.column] }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <no-data v-else :text="$t('rooms.noRooms', { name: entityTitle })"/>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import NoData from '@/components/Allgemein/KeineDaten.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'

const name = 'Rooms'

export default {
    name: name,
    components: { NoData, Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            expanded: {
                bookable: [],
                additional: [],
            },
            search: '',
            mappingCodeColumns: [
                {
                    channel: 'Booking.com (hotel)',
                    width: '',
                    column: 'objausstds_bookingcom_hotel',
                },
                {
                    channel: 'Booking.com (room)',
                    width: '',
                    column: 'objausstds_bookingcom_room',
                },
                {
                    channel: 'Booking.com (bedroom)',
                    width: '',
                    column: 'objausstds_bookingcom_bedroom',
                },
                {
                    channel: 'Booking.com (bathroom)',
                    width: '',
                    column: 'objausstds_bookingcom_bathroom',
                },
                {
                    channel: 'Airbnb',
                    width: '',
                    column: 'objausstds_airbnb_rest',
                },
                {
                    channel: 'Airbnb (room)',
                    width: '',
                    column: 'objausstds_airbnb_rest_room',
                },
                {
                    channel: 'TFW',
                    width: '',
                    column: 'objausstds_tfw',
                },
                {
                    channel: 'HomeAway (unit)',
                    width: '',
                    column: 'objausstds_homeaway_unit',
                },
                {
                    channel: 'HomeAway (listing)',
                    width: '',
                    column: 'objausstds_homeaway_listing',
                },
                {
                    channel: 'HomeAway (bedroom)',
                    width: '',
                    column: 'objausstds_homeaway_bedroom',
                },
                {
                    channel: 'HomeAway (bathroom)',
                    width: '',
                    column: 'objausstds_homeaway_bathroom',
                },
                {
                    channel: 'Holidu',
                    width: '',
                    column: 'objausstds_holidu',
                },
                {
                    channel: 'Yoholida (listing)',
                    width: '',
                    column: 'objausstds_yoholida_listing',
                },
                {
                    channel: 'Yoholida (entity)',
                    width: '',
                    column: 'objausstds_yoholida_entity',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'auslng_titel',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.feondiTitle'),
                    value: 'objausstds_beschreibung',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.value'),
                    value: 'rk2aus_wert',
                    sortable: false,
                    divider: true,
                    width: '100px',
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: '50px',
                },
            ]
        },
    },
    methods: {
        getRoomName(item) {
            const roomNumber = item.rk_raumid
            const values = {
                title: `${ this.$i18n.t('rooms.livingroom') } ${ roomNumber }`,
                subtitle: '',
                icon: 'mdi-sofa-outline',
            }

            if (item.rk_typ === 'S') {
                values.title = `${ this.$i18n.t('rooms.bedroom.title') } ${ roomNumber }`
                values.subtitle = `${ this.$i18n.tc('rooms.bedroom.subtitle', item.rk_parameter1) } ${ item.rk_groesse > 0 ? ', ' + parseFloat(item.rk_groesse) + ' qm' : '' }`
                values.icon = 'mdi-bed-outline'
            } else if (item.rk_typ === 'B') {
                values.title = `${ this.$i18n.t('rooms.bathroom.title') } ${ roomNumber }`
                values.subtitle = `${ this.$i18n.tc('rooms.bathroom.subtitle', item.rk_parameter1) } ${ item.rk_groesse > 0 ? ', ' + parseFloat(item.rk_groesse) + ' qm' : '' }`
                values.icon = 'mdi-shower'
            }

            return values
        },
        rowClick(item, slot) {
            slot.expand(!slot.isExpanded)
        },
    },
}
</script>
