<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('offers.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :single-expand="true"
                        :expanded.sync="expanded"
                        :search="search"
                        :loading="$store.getters.getLoaders.distances"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                    >
                      <template v-slot:item.rabatt_buzeitraumaktiv="{ item }">
                        <activated-deactivated-icon :is-set="item.rabatt_buzeitraumaktiv" />
                      </template>
                      <template v-slot:item.rabatt_buzeitraumvon="{ item }">
                            <span>
                                {{ getFormattedDate(item.rabatt_buzeitraumvon) }}
                            </span>
                        </template>
                        <template v-slot:item.rabatt_buzeitraumbis="{ item }">
                            <span>
                                {{ getFormattedDate(item.rabatt_buzeitraumbis) }}
                            </span>
                        </template>
                      <template v-slot:item.rabatt_reisezeitraumaktiv="{ item }">
                        <activated-deactivated-icon :is-set="item.rabatt_reisezeitraumaktiv" />
                      </template>
                      <template v-slot:item.rabatt_reisezeitraumvon="{ item }">
                            <span>
                                {{ getFormattedDate(item.rabatt_reisezeitraumvon) }}
                            </span>
                        </template>
                        <template v-slot:item.rabatt_reisezeitraumbis="{ item }">
                            <span>
                                {{ getFormattedDate(item.rabatt_reisezeitraumbis) }}
                            </span>
                        </template>
                      <template v-slot:item.rabatt_automatischanwenden="{ item }">
                        <activated-deactivated-icon :is-set="item.rabatt_automatischanwenden" />
                      </template>
                      <template v-slot:item.rabatt_aufgesamtsummeanwenden="{ item }">
                        <activated-deactivated-icon :is-set="item.rabatt_aufgesamtsummeanwenden" />
                      </template>
                        <template v-slot:item.rabatt_prozent="{ item }">
                            <span>
                                {{ parseFloat(item.rabatt_prozent).toFixed(2).replace('.', ',') + ' %' }}
                            </span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'

const name = 'Discounts'

export default {
    name: name,
    components: { ActivatedDeactivatedIcon, Search },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            expanded: [],
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'rabatt_name',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.bookingPeriodActive'),
                    value: 'rabatt_buzeitraumaktiv',
                    align: 'center',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.bookingFrom'),
                    value: 'rabatt_buzeitraumvon',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.bookingTo'),
                    value: 'rabatt_buzeitraumbis',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.stayPeriodActive'),
                    value: 'rabatt_reisezeitraumaktiv',
                    align: 'center',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.stayFrom'),
                    value: 'rabatt_reisezeitraumvon',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.stayTo'),
                    value: 'rabatt_reisezeitraumbis',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.words.minNightsBeforeArrival'),
                    value: 'rabatt_buminnaechtevoranreise',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.minStay'),
                    value: 'rabatt_minlaengeanzahlnaechte',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.words.applyAutomatically'),
                    value: 'rabatt_automatischanwenden',
                    align: 'center',
                    divider: true,
                    width: '160px',
                },
                {
                    text: this.$i18n.t('common.words.applyOnTotal'),
                    value: 'rabatt_aufgesamtsummeanwenden',
                    align: 'center',
                    divider: true,
                    width: '160px',
                },
                {
                    text: this.$i18n.t('common.words.percent'),
                    value: 'rabatt_prozent',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.words.amount'),
                    value: 'rabatt_betrag',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
            ]
        },
    },
    methods: {
        getFormattedDate(dateAsString) {
            if (dateAsString)
                return moment(dateAsString).format(this.$i18n.t('common.date.format').toString())
            return ''
        },
    },
}
</script>
