var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.$t('surcharges.heading', { name: _vm.entityTitle, memberid: _vm.getActingMemberid }))+" ")]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-4 pl-0"},[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.surcharges,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},scopedSlots:_vm._u([{key:"item.zu_von",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.zu_von))+" ")])]}},{key:"item.zu_bis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.zu_bis))+" ")])]}},{key:"item.zu_prozent",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.zu_prozent).toFixed(2).replace('.', ',') + ' %')+" ")])]}},{key:"item.zu_preis",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.zu_preis).toFixed(2).replace('.', ','))+" ")])]}},{key:"item.zu_zeitraumaktiv",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.zu_zeitraumaktiv}})]}},{key:"item.zu_kurzbucher",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.zu_kurzbucher}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }