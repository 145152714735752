<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0" v-html="$t('amenities.heading', {name: entityTitle, memberid: getActingMemberid})"/>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-4 pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        show-expand
                        :single-expand="true"
                        :expanded.sync="expanded"
                        :search="search"
                        :loading="$store.getters.getLoaders.distances"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        @click:row="rowClick"
                    >

                        <template v-slot:item.objausstds_beschreibung="{ item }">
                            <span v-if="item.objausstds_beschreibung">
                                {{ item.objausstds_beschreibung }}
                            </span>
                            <span
                                v-else
                                :style="`color: ${getColors.error}`"
                                v-html="$t('common.words.notAssigned')"
                            />
                        </template>

                        <template v-slot:item.objaus_wert="{ item }">
                            <span v-if="item.objaus_wert">
                                {{ item.objaus_wert }}
                            </span>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td class="px-0" :colspan="headers.length">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th
                                                v-for="(headColumns, headColumnsIndex) in [$tc('common.words.channels', 2), $t('common.labels.mappingCode')]"
                                                :key="`mappingCodeColumnIndex-${headColumnsIndex}`"
                                                class="text-left text-uppercase font-weight-black"
                                            >
                                                {{ headColumns }}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            style=";background-color:rgba(0,0,0,0.03)"
                                            v-for="(mappingCodeColumn, mappingCodeColumnIndex) in mappingCodeColumns"
                                            :key="`mappingCodeColumnIndex-${mappingCodeColumnIndex}`"
                                        >
                                            <td
                                                style="border-radius:0"
                                                class="v-data-table__divider"
                                                :style="`width:${mappingCodeColumn.width}`"
                                            >
                                                {{ mappingCodeColumn.channel }}
                                            </td>
                                            <td
                                                style="border-radius:0"
                                                class="v-data-table__divider"
                                                :style="`width:${mappingCodeColumn.width}`"
                                            >
                                                  <span v-if="item[mappingCodeColumn.column]">
                                                    {{ item[mappingCodeColumn.column] }}
                                                  </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'

const name = 'Amenities'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String
    },
    data() {
        return {
            name: name,
            expanded: [],
            search: '',
            mappingCodeColumns: [
                {
                    channel: 'Booking.com (hotel)',
                    width: '',
                    column: 'objausstds_bookingcom_hotel',
                },
                {
                    channel: 'Booking.com (room)',
                    width: '',
                    column: 'objausstds_bookingcom_room',
                },
                {
                    channel: 'Booking.com (bedroom)',
                    width: '',
                    column: 'objausstds_bookingcom_bedroom',
                },
                {
                    channel: 'Booking.com (bathroom)',
                    width: '',
                    column: 'objausstds_bookingcom_bathroom',
                },
                {
                    channel: 'Airbnb',
                    width: '',
                    column: 'objausstds_airbnb_rest',
                },
                {
                    channel: 'Airbnb (room)',
                    width: '',
                    column: 'objausstds_airbnb_rest_room',
                },
                {
                    channel: 'TFW',
                    width: '',
                    column: 'objausstds_tfw',
                },
                {
                    channel: 'HomeAway (unit)',
                    width: '',
                    column: 'objausstds_homeaway_unit',
                },
                {
                    channel: 'HomeAway (listing)',
                    width: '',
                    column: 'objausstds_homeaway_listing',
                },
                {
                    channel: 'HomeAway (bedroom)',
                    width: '',
                    column: 'objausstds_homeaway_bedroom',
                },
                {
                    channel: 'HomeAway (bathroom)',
                    width: '',
                    column: 'objausstds_homeaway_bathroom',
                },
                {
                    channel: 'Holidu',
                    width: '',
                    column: 'objausstds_holidu',
                },
                {
                    channel: 'Yoholida (listing)',
                    width: '',
                    column: 'objausstds_yoholida_listing',
                },
                {
                    channel: 'Yoholida (entity)',
                    width: '',
                    column: 'objausstds_yoholida_entity',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.customTitle'),
                    value: 'auslng_titel',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.feondiTitle'),
                    value: 'objausstds_beschreibung',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.value'),
                    value: 'objaus_wert',
                    sortable: false,
                    divider: true,
                    width: '100px',
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: '50px',
                },
            ]
        },
    },
    methods: {
        rowClick(item, slot) {
            slot.expand(!slot.isExpanded)
        },
    },
}
</script>
