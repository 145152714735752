<template>
  <div class="w-100 pl-4 mb-4">
    <v-card class="pt-2" outlined>
      <v-card-title>
        {{ property.dynamic_title }} | ID {{ property.obj_id }}
        <div v-if="property.obj_aktiv">
                    <span
                        class="ml-2 caption"
                        :style="'color:' + getColors.success"
                        v-html="$t('common.labels.active')"
                    />
        </div>
        <div v-else>
                    <span
                        class="ml-2 caption"
                        :style="'color:' + getColors.error"
                        v-html="$t('common.labels.inactive')"
                    />
        </div>
      </v-card-title>
      <v-card-text v-html="$i18n.t('property.subheading', {name: property.dynamic_title})" />
      <v-divider />
      <v-tabs
          v-model="tab"
          :background-color="getColors.primary"
          dark
          show-arrows
          color="#ffffff"
          center-active
      >
        <v-tabs-slider :color="getColors.secondary1" />
        <v-tab
            v-for="(tabItem, index) in tabItems"
            :key="`tabItem-${index}`"
            :disabled="tabItem.disabled"
            @change="tab = index"
        >
          <v-progress-circular
              v-if="tabItem.disabled"
              class="mr-1"
              indeterminate
              size="16"
          />
          <v-icon
              v-else
              class="mr-1"
              small
          >
            {{ tabItem.icon }}
          </v-icon>
          {{ tabItem.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
            class="pl-4"
            v-for="(tabItem, index) in tabItems"
            :key="`tabItem-${index}`"
        >
          <component
              v-bind:is="tabItem.component"
              entity-type="property"
              :entity-id="propertyId"
              :entity-title="property['dynamic_title']"
              :items="tabItem.items"
              :sort-images="property['obj_sortimages']"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicDataTab from '@/components/Objekt/Objektstammdaten.vue'
import PhotosTab from '@/components/Allgemein/Fotos.vue'
import PricesTab from '@/components/Objekt/Preise.vue'
import SurchargesTab from '@/components/Objekt/Zuschlaege.vue'
import OccupanciesTab from '@/components/Objekt/Vorgaenge.vue'
import ServicesTab from '@/components/Objekt/Leistungen.vue'
import DistancesTab from '@/components/Objekt/Entfernungen.vue'
import AmenitiesTab from '@/components/Objekt/Ausstattungen.vue'
import OffersTab from '@/components/Objekt/Angebote.vue'
import DiscountsTab from '@/components/Objekt/Rabatte.vue'
import RoomsTab from '@/components/Objekt/Raeume.vue'
import PropertiesService from '@/services/properties.service'

const name = 'Property'

export default {
    name: name,
    components: {
        BasicDataTab,
        PhotosTab,
        PricesTab,
        SurchargesTab,
        OccupanciesTab,
        ServicesTab,
        DistancesTab,
        AmenitiesTab,
        OffersTab,
        DiscountsTab,
        RoomsTab,
    },
    metaInfo() {
        return {
            title: this.property['dynamic_title'],
        }
    },
    sockets: {
        async propertyUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getProperty()
                await this.bannerMessageForSocketEvent('property', data.memberid)
            }
        },
        async propertyImagesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getPhotos()
                await this.bannerMessageForSocketEvent('photos', data.memberid)
            }
        },
        async pricesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getPrices()
                await this.bannerMessageForSocketEvent('prices', data.memberid)
            }
        },
        async surchargesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getSurcharges()
                await this.bannerMessageForSocketEvent('surcharges', data.memberid)
            }
        },
        async occupanciesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getOccupancies()
                await this.bannerMessageForSocketEvent('occupancies', data.memberid)
            }
        },
        async servicesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getServices()
                await this.bannerMessageForSocketEvent('services', data.memberid)
            }
        },
        async distancesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getDistances()
                await this.bannerMessageForSocketEvent('distances', data.memberid)
            }
        },
        async amenitiesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getAmenities()
                await this.bannerMessageForSocketEvent('amenities', data.memberid)
            }
        },
        async discountsUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getDiscounts()
                await this.bannerMessageForSocketEvent('discounts', data.memberid)
            }
        },
        async roomsUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getRooms()
                await this.bannerMessageForSocketEvent('rooms', data.memberid)
            }
        },
    },
    data() {
        return {
            name: name,
            property: {},
            photos: [],
            prices: [],
            surcharges: [],
            occupancies: [],
            services: {},
            distances: [],
            amenities: [],
            rooms: [],
            offers: [],
            discounts: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLoaders',
            'getTab',
            'getActingMemberid',
        ]),
        propertyId: function () {
            return parseInt(this.$route.params.propertyid)
        },
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            return [
                {
                    title: this.$i18n.t('common.tabs.basicData'),
                    icon: 'mdi-home',
                    component: 'BasicDataTab',
                    disabled: this.getLoaders.property,
                    items: this.property,
                },
                {
                    title: this.$i18n.t('common.tabs.photos'),
                    icon: 'mdi-image-multiple',
                    component: 'PhotosTab',
                    disabled: this.getLoaders.propertyImages,
                    items: this.photos,
                },
                {
                    title: this.$i18n.t('common.tabs.prices'),
                    icon: 'mdi-cash',
                    component: 'PricesTab',
                    disabled: this.getLoaders.prices,
                    items: this.prices,
                },
                {
                    title: this.$i18n.t('common.tabs.surcharges'),
                    icon: 'mdi-credit-card',
                    component: 'SurchargesTab',
                    disabled: this.getLoaders.surcharges,
                    items: this.surcharges,
                },
                {
                    title: this.$i18n.t('common.tabs.occupancies'),
                    icon: 'mdi-book-open-variant',
                    component: 'OccupanciesTab',
                    disabled: this.getLoaders.occupancies,
                    items: this.occupancies,
                },
                {
                    title: this.$i18n.t('common.tabs.services'),
                    icon: 'mdi-room-service',
                    component: 'ServicesTab',
                    disabled: this.getLoaders.services,
                    items: this.services,
                },
                {
                    title: this.$i18n.t('common.tabs.distances'),
                    icon: 'mdi-map-marker-distance',
                    component: 'DistancesTab',
                    disabled: this.getLoaders.distances,
                    items: this.distances,
                },
                {
                    title: this.$i18n.t('common.tabs.amenities'),
                    icon: 'mdi-kettle-steam',
                    component: 'AmenitiesTab',
                    disabled: this.getLoaders.amenities,
                    items: this.amenities,
                },
                {
                    title: this.$i18n.t('common.tabs.rooms'),
                    icon: 'mdi-floor-plan',
                    component: 'RoomsTab',
                    disabled: this.getLoaders.rooms,
                    items: this.rooms,
                },
                {
                    title: this.$i18n.t('common.tabs.offers'),
                    icon: 'mdi-hand-heart',
                    component: 'OffersTab',
                    disabled: this.getLoaders.offers,
                    items: this.offers,
                },
                {
                    title: this.$i18n.t('common.tabs.discounts'),
                    icon: 'mdi-label-percent',
                    component: 'DiscountsTab',
                    disabled: this.getLoaders.discounts,
                    items: this.discounts,
                },
            ]
        },
    },
    methods: {
        async bannerMessageForSocketEvent(dataType, memberid) {
            await this.$store.dispatch('socketEventBanner', {
                message: `Some ${dataType} changed for member ${memberid}!`,
                autoHide: true,
            })
        },
        async getProperty() {
            await this.$store.dispatch('setLoaders', { property: true })
            this.property = await PropertiesService.getPropertyByMemberAndPropertyId({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { property: false })
        },
        async getPhotos() {
            await this.$store.dispatch('setLoaders', { propertyImages: true })
            this.photos = await PropertiesService.getPropertyImages({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { propertyImages: false })
        },
        async getPrices() {
            await this.$store.dispatch('setLoaders', { prices: true })
            this.prices = await PropertiesService.getPropertyPrices({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { prices: false })
        },
        async getSurcharges() {
            await this.$store.dispatch('setLoaders', { surcharges: true })
            this.surcharges = await PropertiesService.getPropertySurcharges({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { surcharges: false })
        },
        async getOccupancies() {
            await this.$store.dispatch('setLoaders', { occupancies: true })
            this.occupancies = await PropertiesService.getPropertyOccupancies({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { occupancies: false })
        },
        async getServices() {
            await this.$store.dispatch('setLoaders', { services: true })
            this.services = await PropertiesService.getPropertyServices({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { services: false })
        },
        async getDistances() {
            await this.$store.dispatch('setLoaders', { distances: true })
            this.distances = await PropertiesService.getPropertyDistances({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { distances: false })
        },
        async getAmenities() {
            await this.$store.dispatch('setLoaders', { amenities: true })
            this.amenities = await PropertiesService.getPropertyAmenities({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { amenities: false })
        },
        async getRooms() {
            await this.$store.dispatch('setLoaders', { rooms: true })
            this.rooms = await PropertiesService.getPropertyRooms({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { rooms: false })
        },
        async getOffers() {
            await this.$store.dispatch('setLoaders', { offers: true })
            this.offers = await PropertiesService.getPropertyOffers({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { offers: false })
        },
        async getDiscounts() {
            await this.$store.dispatch('setLoaders', { discounts: true })
            this.discounts = await PropertiesService.getPropertyDiscounts({
                memberid: this.getActingMemberid,
                objid: this.propertyId,
            })
            await this.$store.dispatch('setLoaders', { discounts: false })
        },
    },
    async mounted() {

        if (this.$route.query.hasOwnProperty('target') && this.$route.query.target === 'blank')
            this.$store.commit('SET_TAB', 0)

        this.tabItems[0].disabled = false

        await Promise.all([
            this.getProperty(),
            this.getPhotos(),
            this.getPrices(),
            this.getSurcharges(),
            this.getOccupancies(),
            this.getServices(),
            this.getDistances(),
            this.getAmenities(),
            this.getRooms(),
            this.getOffers(),
            this.getDiscounts(),
        ])
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['tab'])
        next()
    },
}
</script>
