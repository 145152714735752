<template>
    <div>
        <v-card flat>
            <v-card-title class="pl-0">
                {{ $t('prices.heading', { name: entityTitle, memberid: getActingMemberid }) }}
                <v-spacer/>
                <v-card-actions class="pa-0">
                    <v-btn
                        plain
                        text
                        small
                        :color="getColors.secondary2"
                        @click="pricesFiltered = !pricesFiltered"
                    >
                        {{ $t('prices.endsBefore', { state: showHideTranslated, date: currentDateFormatLocale }) }}
                    </v-btn>
                </v-card-actions>
            </v-card-title>
            <v-divider class="mb-4"/>
            <v-row>
                <v-col class="pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-4 pl-0">
                    <v-data-table
                        :headers="headers"
                        :items="priceItems"
                        :search="search"
                        :loading="$store.getters.getLoaders.prices"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        single-select
                        @click:row="rowClick"
                    >
                        <template v-slot:item.preis_von="{ item }">
                            <span>
                                {{ getFormattedDate(item.preis_von) }}
                            </span>
                        </template>
                        <template v-slot:item.preis_bis="{ item }">
                            <span>
                                {{ getFormattedDate(item.preis_bis) }}
                            </span>
                        </template>
                        <template v-slot:item.preis_preis="{ item }">
                            <span>
                                {{ parseFloat(item.preis_preis).toFixed(2).replace('.', ',') }}
                            </span>
                        </template>
                        <template v-slot:item.preis_wochenpreis="{ item }">
                            <span
                                :style="'color: ' + (item.preis_wochenpreis > 0 && item.preis_wochenpreis <= item.preis_preis ? $store.getters.getColors.error : '')">
                                {{ parseFloat(item.preis_wochenpreis).toFixed(2).replace('.', ',') }}
                            </span>
                        </template>
                        <template v-slot:item.preis_weitere="{ item }">
                            <span>
                                {{ parseFloat(item.preis_weitere).toFixed(2).replace('.', ',') }}
                            </span>
                        </template>
                        <template v-slot:item.preis_mwst="{ item }">
                            <span>
                                {{ parseFloat(item.preis_mwst).toFixed(2).replace('.', ',') + ' %' }}
                            </span>
                        </template>
                        <template v-slot:item.preis_mintage="{ item }">
                            <span>
                                {{ item.preis_mintage }}
                            </span>
                        </template>
                        <template v-slot:item.preis_anreise="{ item }">
                            <span v-if="item.preis_anreise.split(',').length === 1">
                                {{ getArrivalAndDepartureDaysText(item.preis_anreise) }}
                            </span>
                            <t-t-custom
                                v-else
                                v-bind:tt-message="getArrivalAndDepartureDaysText(item.preis_anreise)"
                                tt-icon="mdi-information-outline"
                                :tt-color="$store.getters.getColors.warning"
                            />
                        </template>
                        <template v-slot:item.preis_abreise="{ item }">
                            <span v-if="item.preis_abreise.split(',').length === 1">
                                {{ getArrivalAndDepartureDaysText(item.preis_abreise) }}
                            </span>
                            <t-t-custom
                                v-else
                                v-bind:tt-message="getArrivalAndDepartureDaysText(item.preis_abreise)"
                                tt-icon="mdi-information-outline"
                                :tt-color="$store.getters.getColors.warning"
                            />
                        </template>
                        <template v-slot:item.preis_lueckenbuchungok="{ item }">
                            <activated-deactivated-icon :is-set="item.preis_lueckenbuchungok"/>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import TTCustom from '@/components/Tooltips/TTFreierText.vue'
import socket from '@/mixins/socket.mixin'
import moment from 'moment'
import { mapGetters } from 'vuex'

const name = 'Prices'

export default {
    name: name,
    components: { Search, ActivatedDeactivatedIcon, TTCustom },
    mixins: [socket],
    metaInfo: {
        title: name,
    },
    props: {
        items: Array,
        entityTitle: String,
    },
    data() {
        return {
            name: name,
            prices: [],
            pricesFiltered: true,
            showHide: 'show',
            search: '',
            rowSelected: false
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        showHideTranslated: function () {
            return this.$i18n.t(`common.words.${this.showHide}`)
        },
        currentDateFormatLocale: function () {
            return moment().format(this.$i18n.t('common.date.format').toString())
        },
        priceItems: function () {
            if (this.pricesFiltered) {
                this.showHide = 'show'
                return this.prices.filter(elem => new Date(elem.preis_bis) >= new Date())
            }

            this.showHide = 'hide'
            return this.prices
        },
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.title'),
                    value: 'preislng_titel',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.from'),
                    value: 'preis_von',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.to'),
                    value: 'preis_bis',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.persons'),
                    value: 'preis_personen',
                    align: 'end',
                    divider: true,
                    width: '110px',
                },
                {
                    text: this.$i18n.t('common.labels.price'),
                    value: 'preis_preis',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.priceWeek'),
                    value: 'preis_wochenpreis',
                    align: 'end',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.priceFuther'),
                    value: 'preis_weitere',
                    align: 'end',
                    divider: true,
                    width: '140px',
                },
                {
                    text: this.$i18n.t('common.labels.tax'),
                    value: 'preis_mwst',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.minStay'),
                    value: 'preis_mintage',
                    align: 'end',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.arrivalDays'),
                    value: 'preis_anreise',
                    align: 'center',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.departureDays'),
                    value: 'preis_abreise',
                    align: 'center',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.gapBooking'),
                    value: 'preis_lueckenbuchungok',
                    align: 'center',
                    sortable: false,
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.minGapDefinition'),
                    value: 'preis_mintagefuerluecke',
                    align: 'center',
                    sortable: false,
                    divider: true,
                    width: '120px',
                },
            ]
        },
    },
    methods: {
        rowClick: function (item, row) {
            row.select(this.rowSelected = !this.rowSelected);
        },
        getFormattedDate(dateAsString) {
            if (dateAsString)
                return moment(dateAsString).format(this.$i18n.t('common.date.format').toString())

            return ''
        },
        getArrivalAndDepartureDaysText(days) {
            if (!days)
                return ''

            const weekdays = []
            days.split(',').forEach(val => {
                switch (val) {
                    case '0':
                        weekdays.push(this.$i18n.t('common.date.weekDays.sunday'))
                        break

                    case '1':
                        weekdays.push(this.$i18n.t('common.date.weekDays.monday'))
                        break

                    case '2':
                        weekdays.push(this.$i18n.t('common.date.weekDays.thuesday'))
                        break

                    case '3':
                        weekdays.push(this.$i18n.t('common.date.weekDays.wednesday'))
                        break

                    case '4':
                        weekdays.push(this.$i18n.t('common.date.weekDays.thursday'))
                        break

                    case '5':
                        weekdays.push(this.$i18n.t('common.date.weekDays.friday'))
                        break

                    case '6':
                        weekdays.push(this.$i18n.t('common.date.weekDays.saturday'))
                        break

                    case '9':
                        weekdays.push(this.$i18n.t('common.words.always'))
                        break
                }
            })

            return weekdays.join(', ')
        },
    },
    mounted() {
        this.prices = this.items
    },
}
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
    background: #34495e30 !important;
}
</style>