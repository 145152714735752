<template>
    <v-row>
        <v-col class="pl-0">
            <v-card flat>
                <v-card-title :style="`color:${getColors.error}`" v-html="text"/>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'NoData',
    props: ['text'],
        computed: {
        ...mapGetters([
            'getColors',
            'getAlert',
        ]),
    },
}
</script>
